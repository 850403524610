import H3 from './H3';
import Paragraph from './Paragraph';
import getImageData from '../helpers/ImageHelper';
import NMImage from './shared/NMImage';

export const AvatarCard = ({...data}) => (
  // <div className="flex space-x-6 w-full md:basis-1/3 md:pr-12">
  <div className="flex items-center space-x-6 w-full">
    <div className="h-24 w-full max-w-[96px]">
      <NMImage
        src={getImageData(data.image).src}
        alt={getImageData(data.image).alt}
        layout="fixed"
        width="96"
        height="96"
        className="rounded-full object-contain"
      />
    </div>
    <div className="flex-1">
      <H3 className="text-dark-800" text={data.names} />
      <Paragraph className="pt-1 text-dark-400" text={data.role} />
      <Paragraph className="pt-3 text-dark-400" text={data.text} />
    </div>
  </div>
);
