import { Header } from './Header';
import RichTextParser from './RichTextParser';

export const TextBlock = ({ ...data }) => (
  <div
    className={`full-width-section text-block-wrapper ${
      data?.backgroundColor === 'accent' ? ' bg-secondary-extraLight' : ''
    }`}
  >
    <div className="content-wrapper">
      <div className="mx-auto w-full max-w-[838px] pt-6 pb-6 text-dark-800">
        {data?.title && (
          <Header
            size={data?.title_size}
            className={data?.title_size === 'h2' && 'pt-16 pb-4'}
            text={data?.title}
          />
        )}
        <RichTextParser className="text-block-content">{data?.text}</RichTextParser>
      </div>
    </div>
  </div>
);
