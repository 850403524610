import React from 'react'
import H5 from './H5'

function NumberedCard({index, title}) {
  return (
    <div className='w-full max-w-[395px] p-6 bg-secondary-extraLight rounded-xl'>
      <div className='grid gap-4 mx-auto grid-flow-col'>
        <div className=' w-14 h-14 rounded-full col-[1] bg-secondary-light text-dark-800 flex items-center justify-center'>
          <H5 className="text-dark-800 col-[2]" text={index} />
        </div>
        <H5 className="text-dark-800 col-[2]" text={title} />
      </div>
    </div>
  )
}

export default NumberedCard