import H2 from './H2';
import NumberedCard from './NumberedCard';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

function NumberedCardGrid({ cards, button, title }) {
  return (
    <div className="mx-auto max-w-7xl md:mx-6 xl:mx-auto text-dark-800">
      <H2 text={title} className="text-center" />
      <div className="flex flex-col gap-6 pt-12 md:flex-row md:gap-12">
        {cards.map((card) => (
          <NumberedCard key={card.id} {...card} />
        ))}
      </div>
      {button && (
        <div className="pt-12 text-center">
          <Button type="tertiary" className="w-full text-center lg:w-auto">
            <span className="font-poppins text-base font-medium text-dark-800">
              <FontAwesomeIcon icon={faArrowRight} /> {button.text}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default NumberedCardGrid;
