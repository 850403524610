/* eslint-disable max-len */
import React from 'react';
import RichTextParser from './RichTextParser';
import getImageData from '../helpers/ImageHelper';
import NMImage from './shared/NMImage';

function TextWithIcon({
  icon, text,
}) {
  return (
    <div className="w-full relative pb-16 xl:mt-24 xl:pb-24">
      <div className="w-full 2xl:max-w-[1280px] xl:max-w-[830px]lg:max-w-[830px] md:max-w-[830px] mx-auto px-6">
        <div className="flex items-center justify-center md:h-[64px] w-[60px] h-[48px] mx-auto">
          <NMImage src={getImageData(icon).src} alt={getImageData(icon).alt} width={getImageData(icon).width} height={getImageData(icon).height} />
        </div>
        <div className="m-auto text-center md:text-[32px] max-w-[60ch] my-0 mx-auto text-2xl font-nature-meet-standard pt-5 leading-normal text-primary-main">
          <RichTextParser>{text}</RichTextParser>
        </div>
      </div>
    </div>
  );
}

export default TextWithIcon;
