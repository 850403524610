import React from 'react';
import Button from './Button';
import getImageData from '../helpers/ImageHelper';
import RichTextParser from './RichTextParser';
import { Header } from './Header';
import NMImage from './shared/NMImage';

function ImageWithText({ image, title, titleSize, reverse, text, button }) {
  return (
    <div className="mx-auto max-w-7xl py-[25px] md:mx-6 lg:py-[120px] xl:mx-auto">
      <div
        className={`flex items-center gap-8 md:gap-12 ${
          reverse ? 'flex-col-reverse md:flex-row' : 'flex-col md:flex-row-reverse'
        }`}
      >
        <div className="flex-1 text-dark-800">
          {title && <Header size={titleSize || 'h2'} text={title} className="text-dark-800" />}
          {text && <RichTextParser className="text-block-content pt-6">{text}</RichTextParser>}
          {button && (
            <Button
              type={button?.type}
              link={button?.url}
              newTab={button?.newTab}
              className="mt-8 block w-full text-center lg:w-auto"
              id={button?.id}
            >
              <span className="font-poppins text-base font-medium text-dark-800">
                {button?.text}
              </span>
            </Button>
          )}
        </div>
        <div className="flex-1">
          <NMImage
            layout="responsive"
            src={getImageData(image).src}
            className="object-cover object-center"
            alt={title}
            width={616}
            height={463}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageWithText;
