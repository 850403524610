import Button from './Button';
import * as faIcons from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import H2 from './H2';
import SubHeadline from './SubHeadline';
import GenericCard from './GenericCard';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';

const ButtonsSection = ({ buttons, isHorizontal }) => (
  <div
    className={`flex flex-col items-center ${
      !isHorizontal && 'justify-center'
    } gap-6 pt-12 md:flex-row`}
  >
    {buttons?.map((button) => (
      <span key={`${button.id}${Math.random()}`}>
        {button?.type === 'secondary' && (
          <Button
            type={button?.type}
            link={button?.url}
            newTab={button?.newTab}
            className="text-center lg:w-auto"
            id={button?.id}
          >
            <span className="font-poppins text-base font-medium text-white">
              <FontAwesomeIcon icon={faArrowRightLong} /> {button?.text}
            </span>
          </Button>
        )}
        {button?.type === 'outline' && (
          <Button type={button?.type} className="text-center lg:w-auto">
            <span className="font-poppins text-base font-medium">{button?.text}</span>
          </Button>
        )}
      </span>
    ))}
  </div>
);

export default function TextCardGrid({ cards, buttons, title, text, orientation }) {
  const isHorizontal = orientation === 'horizontal';
  return (
    <div
      className={`full-width-section w-screen bg-tertiary-extraLight py-16 px-6 text-left text-dark-800 md:py-24 md:px-0 ${
        !isHorizontal && 'md:text-center'
      }`}
    >
      <div
        className={`content-wrapper mx-auto flex w-full ${
          isHorizontal ? 'flex-col items-center lg:flex-row lg:space-x-12' : 'flex-col'
        }`}
      >
        <div className="flex-1">
          <H2 text={title} className="text-dark-800 " />
          <SubHeadline text={text} className="pt-6 text-dark-800" />
          {isHorizontal && <ButtonsSection buttons={buttons} isHorizontal />}
        </div>
        <div
          className={`mx-auto flex max-w-7xl flex-1 flex-col justify-center ${
            isHorizontal ? 'grid-cols-2 gap-5 sm:grid lg:gap-10' : 'gap-12'
          } pt-12 md:flex-row xl:mx-auto`}
        >
          {cards?.map((card) => (
            <GenericCard
              key={card.id}
              icon={faIcons[card?.icon?.type]}
              title={card?.title}
              description={card?.text}
            />
          ))}
        </div>
        {!isHorizontal && <ButtonsSection buttons={buttons} />}
      </div>
    </div>
  );
}
