import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { stripHTML } from '../utils';
import H5 from './H5';

function GenericCard({ icon, title, description, small, className }) {
  return (
    <div className={`inline-flex whitespace-normal align-top news-carousel-card flex-col grow h-full border mx-auto border-solid border-dark-100 p-5 lg:p-6  rounded-xl ${className}
    ${small ? 'w-[196px] md:w-[232px] md:max-w-[232px]' : 'w-full max-w-[327px] md:max-w-[394.67px] text-left bg-white'}`}>
      <FontAwesomeIcon size="xl" icon={icon} className="text-dark-900 mr-auto h-5  text-xs" />
      <H5 text={title} className="text-dark-800 font-poppins pt-4 w-full" />
      <p className="text-dark-800 text-sm font-normal grow leading-[140%] lg:text-base lg:leading-[22.4px] font-sans pt-4 w-full">{stripHTML(description)}</p>
    </div>
  );
}

export default GenericCard;
