import H1 from './H1';
import H2 from './H2';
import H3 from './H3';

export const Header = ({ size, text, className }) => {
  switch (size) {
    case 'h1':
      return <H1 className={className} text={text} />;
    case 'h2':
      return <H2 className={className} text={text} />;
    default:
      return <H3 className={className} text={text} />;
  }
};
