/* eslint-disable react/jsx-props-no-spreading */
import Hero from '../components/Hero';
import Alert from '../components/Alert';
import IconGrid from '../components/IconGrid';
import TextWithIcon from '../components/TextWithIcon';
import TripsCardGrid from '../components/TripsCardGrid';
import TopicsCardGrid from '../components/TopicsCardGrid';
import ImageWithText from '../components/ImageWithText';
import { TextBlock } from '../components/TextBlock';
import { TitleBlock } from '../components/TitleBlock';
import NumberedCardGrid from '../components/NumberedCardGrid';
import AvatarCardGrid from '../components/AvatarCardGrid';
import TextCardGrid from '../components/TextCardGrid';
import { useRouter } from 'next/router';

export default function ComponentParser({ data, popularTopics, featuredTrips }) {
  const router = useRouter();
  // eslint-disable-next-line no-underscore-dangle
  switch (data.__typename) {
    case 'ComponentPageComponentsIconGrid':
      return <IconGrid primary {...data} />;

    case 'ComponentPageComponentsHero':
      return <Hero {...data} />;

    case 'ComponentPageComponentsAlert':
      return <Alert {...data} />;

    case 'ComponentPageComponentsCardGrid':
      return <TripsCardGrid data={data} />;

    case 'ComponentPageComponentsPopularTopics':
      return (
        <TopicsCardGrid
          data={popularTopics}
          title={data?.title}
          onClick={async (topic) => {
            await router.push({
              pathname: '/trips/find',
              query: {
                topics: [topic.id],
              },
            });
          }}
          button={data?.button}
          subHeading
          display
        />
      );

    case 'ComponentPageComponentsTextWithIcon':
      return <TextWithIcon primary {...data} />;

    case 'ComponentPageComponentsTextCardGrid':
      return <TextCardGrid {...data} />;
    case 'ComponentPageComponentsImageWithText':
      return <ImageWithText {...data} />;

    case 'ComponentPageComponentsNumberedCardGrid':
      return <NumberedCardGrid {...data} />;
    case 'ComponentPageComponentsAvatarCardGrid':
      return <AvatarCardGrid {...data} />;
    case 'ComponentPageComponentsTitleBlock':
      return <TitleBlock {...data} />;
    case 'ComponentPageComponentsTextBlock':
      return <TextBlock {...data} />;
    case 'ComponentPageComponentsFeaturedTrips':
      return <>{featuredTrips.length ?
        <TripsCardGrid showDetails featured data={{
          id: data?.id,
          title: data?.Title,
          subtitle: data?.subtitle,
          button: data?.button,
          Cards: featuredTrips,
        }} />
        : null} </>
    case 'ComponentPageComponentsTopicsGrid':
      return <TopicsCardGrid
        onClick={async (topic) => {
          await router.push({
            pathname: '/trips/find',
            query: {
              topics: [topic.id],
            },
          });
        }}
        pageTop
        pagePadding
        data={popularTopics}
        subHeading
        title={data?.title}
        button={{
          text: "Load more",
          url: "#"
        }}
        allVisible
      />;
    default:
      return null;
  }
}
