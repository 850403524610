import React from 'react';
import H2 from './H2';
import SubHeadline from './SubHeadline';
import { AvatarCard } from './AvatarCard';

function AvatarCardGrid({ cards, title, subtitle }) {
  return (
    <div className="mx-auto max-w-7xl pb-16 md:mx-6 md:py-24 xl:mx-auto text-dark-800">
      <H2 text={title} className="text-center text-dark-800" />
      <SubHeadline text={subtitle} className="pt-4 text-center " />
      {/* <div className="flex flex-wrap flex-col md:flex-row pt-12 md:justify-center gap-y-8 md:gap-y-12"> */}
      <div className="grid grid-cols-1 md:grid-cols-3  md:gap-12 pt-12 gap-8">
        {cards?.map((card) => (
          <AvatarCard key={`avatar-card-${card.id}`} {...card} />
        ))}
      </div>
    </div>
  );
}

export default AvatarCardGrid;
