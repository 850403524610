import H2 from './H2';
import Paragraph from './Paragraph';

export const TitleBlock = ({ ...data }) => (
  <div
    className={`full-width-section text-block-wrapper ${
      data?.backgroundColor === 'accent' ? ' bg-secondary-extraLight' : ''
    }`}
  >
    <div className="content-wrapper">
      <div className="mx-auto max-w-[838px] pt-32 pb-6 text-dark-800">
        {data?.title && <H2 text={data?.title} />}
        {data?.subtitle && <Paragraph className="pt-4" text={data?.subtitle} />}
      </div>
    </div>
  </div>
);
